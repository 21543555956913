import { KeyService } from '@/services/KeyService'
import { UserService } from "@/services/UserService";
import { IMPERSONATOR_USERS } from "@/services/ConstantService";
import {GlobalService} from "@/services/GlobalService";

const state = {
  userKeys: [],
  usersByRoleLoaded: false,
  usersByRole: {
    director: {
      name: 'Director',
      users: [],
    },
    general_manager: {
      name: 'General Manager',
      users: [],
    },
    manager: {
      name: 'Manager',
      users: [],
    },
    instructor: {
      name: 'Instructor',
      users: [],
    },
    vessel: {
      name: 'Vessel',
      users: [],
    },
    superintendent: {
      name: 'Superintendent',
      users: [],
    },
  },
  allUsers: [],
  permissionKeys: [],
  staticPermissionKeys: {},
  userPermissions: [],
  selectedUser: [],
}

const mutations = {
  // eslint-disable-next-line no-return-assign
  SET_USERS_BY_ROLE: (state, usersByRole) => state.usersByRole = usersByRole,
  SET_USERS_BY_ROLE_LOADED: (state, usersByRoleLoaded) => state.usersByRoleLoaded = usersByRoleLoaded,
  // eslint-disable-next-line no-return-assign
  setUserKeys: (state, userKeys) => state.userKeys = userKeys,
  // eslint-disable-next-line no-return-assign
  setAllUsers: (state, allUsers) => state.allUsers = allUsers,
  // eslint-disable-next-line no-return-assign
  setUserPermissions: (state, userPermissions) => state.userPermissions = userPermissions,
  // eslint-disable-next-line no-return-assign
  setSelectedUser: (state, selectedUser) => state.selectedUser = selectedUser,
  // eslint-disable-next-line no-return-assign
  setPermissionKeys: (state, permissionKeys) => state.permissionKeys = permissionKeys,
  // eslint-disable-next-line no-return-assign
  setStaticPermissionKeys: (state, staticPermissionKeys) => state.staticPermissionKeys = staticPermissionKeys,
}

const actions = {
  async getUserKeys ({ commit }, params) {
    const userKeys = await KeyService.getUserKeys(params)
    if (userKeys) commit('setUserKeys', userKeys)
  },
  async getAllUsers ({ commit }, params) {
    const allUsers = await UserService.getUserList(params)
    if (allUsers) {
      commit('setAllUsers', allUsers.data)
      commit('setPagination',allUsers)
    }
  },
  async defineSelectedUser ({ commit }, user_id_or_user) {
    let selectedUser = user_id_or_user;

    if (!user_id_or_user) {
      return selectedUser;
    }

    // note: check if given argument is an User ID or else it will be treated as an User Obeject
    if (typeof user_id_or_user === 'string' || user_id_or_user instanceof String || typeof user_id_or_user === 'number' || user_id_or_user instanceof Number) {
      selectedUser = await UserService.getUser(user_id_or_user)
    }

    commit('setSelectedUser', selectedUser)
    commit('setUserPermissions', selectedUser.roles_permissions.permissions)

    return selectedUser;
  },
  async getPermissionKeys({commit}){
    const permissions = await KeyService.getUserPermissionKeys()
    if (permissions) commit('setPermissionKeys', permissions)
  },
  async getStaticPermissionKeys({commit}){
    const permissions = await KeyService.getUserStaticPermissionKeys()
    if (permissions) commit('setStaticPermissionKeys', permissions)
  },
  async impersonateGetUsers({commit, dispatch, state}) {
    if (state.usersByRoleLoaded) {
      return false;
    }

    let  stateUsersByRole = state.usersByRole;
    let  newUsersByRole = stateUsersByRole;

    const response = await GlobalService.ajax('get', IMPERSONATOR_USERS);

    if (response.status == 200) {
      const usersByRole = response.data;

      Object.keys(stateUsersByRole).forEach(role => {
        let roleName = role + '';
        roleName = roleName.replaceAll('_', ' ');
        roleName = roleName.replace(/\w\S*/g, txt => (txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()));

        newUsersByRole[role].name = roleName;
        newUsersByRole[role].users = usersByRole[role];
      });

      commit('SET_USERS_BY_ROLE', newUsersByRole);
      dispatch('impersonateSetUsersByRoleLoaded', true);
    }
  },
  impersonateSetUsersByRoleLoaded({commit}, value) {
    commit('SET_USERS_BY_ROLE_LOADED', value);
  }
}

const getters = {
  usersByRoleLoaded: state => state.usersByRoleLoaded,
  usersByRole: state => state.usersByRole,
  userKeys: state => state.userKeys,
  allUsers: state => state.allUsers,
  userPermissions: state => state.userPermissions,
  selectedUser: state => state.selectedUser,
  permissionKeys: state => state.permissionKeys,
  staticPermissionKeys: state => state.staticPermissionKeys,
}

export default {
  state,
  mutations,
  actions,
  getters
}
