import { KeyService } from '../../services/KeyService'

const state = {
  mngmntOfficeKeys: []
}

const mutations = {
  // eslint-disable-next-line no-return-assign
  setMngmtOfficeKeys: (state, mngmntOfficeKeys) => state.mngmntOfficeKeys = mngmntOfficeKeys,
  // eslint-disable-next-line no-return-assign
  SET_MNGMT_OFFICE_KEYS: (state, mngmntOfficeKeys) => state.mngmntOfficeKeys = mngmntOfficeKeys
}

const actions = {
  async getMngmtOfficeKeys ({ commit },params) {
    const mngmntOfficeKeys = await KeyService.getMngmtOfficeKeys(params)
    if (mngmntOfficeKeys) commit('setMngmtOfficeKeys', mngmntOfficeKeys)
  }
}

const getters = {
  mngmntOfficeKeys: state => state.mngmntOfficeKeys
}

export default {
  state,
  mutations,
  actions,
  getters
}
