import {GlobalService} from "../../services/GlobalService";
import {CI_COMMENT_INDEX, CI_INDEX, CI_VESSEL_INDEX} from "../../services/ConstantService";
import {UrlService} from "../../services/UrlService";
import attachment from "@/components/modules/accident-incident/Attachment.vue";

const state= {
  companyInstructions :[],
  instructionAttachments:[],
  instructionComments:[],
  instructionFiles:[],
  activetrees: [],
}

const mutations = {
  SET_COMPANY_INSTRUCTIONS:(state,instructions)=>state.companyInstructions = instructions,
  SET_COMPANY_INSTRUCTION_ATTACHMENTS:(state,attachments)=>state.instructionAttachments=attachments,
  SET_COMPANY_INSTRUCTION_FILES:(state,attachments)=>state.instructionFiles=attachments,
  SET_COMPANY_INSTRUCTION_COMMENTS:(state,comments)=>state.instructionComments = comments,
  APPEND_COMMENTS:(state,comments)=>{
    if(Array.isArray(comments)) {
      comments.forEach(comment=>{
        state.instructionComments.unshift(comment)
      })
    }
    else {
      state.instructionComments.push(comments)
    }
  },
  SET_ACTIVE_TREES: (state, trees) => state.activetrees = trees
}

const actions = {

 async getCompanyInstructions({commit},payload){
     const uri = [CI_INDEX].join('');
     const queryString = UrlService.setQueryString(payload);
     const response = await  GlobalService.ajax('get', `${uri+queryString}`,{});
     if(response.status === 200){
       commit('SET_COMPANY_INSTRUCTIONS', !payload.hasOwnProperty('page') ? response.data : response.data.data)
       if(payload.hasOwnProperty('page')){
         commit('setPagination',response.data)
       }
       return true;
     }
     return false;
  },
  async addCompanyInstruction({commit},params){
    const uri = [CI_INDEX].join('');
    const response = await  GlobalService.ajax('post',uri,params);
    return response.status === 200;
  },

  async updateCompanyInstruction({commit},{params:params,id:id}){
    const uri = [CI_INDEX,id,'update'].join('/');
    const response = await  GlobalService.ajax('post',uri,params);
    return response.status === 200;
  },

  async deleteCompanyInstruction({commit},params){
    const uri = [CI_INDEX,params.id].join('/');
    params._source='Company Instructions';
    const response = await  GlobalService.ajax('delete',uri,params);
    return response.status === 200;
  },

  async sendVesselResponse({commit},params) {
    const uri = [CI_VESSEL_INDEX,params.id].join('/');
    params._source='Company Instructions';
    const response = await  GlobalService.ajax('put',uri,params);
    return response?.data ?? false;

  },

  async addVesselAttachments({commit},params) {
    commit('SET_COMPANY_INSTRUCTION_ATTACHMENTS',[])
    params._source='Company Instructions';
    const uri = [CI_VESSEL_INDEX,params.id,'attachment'].join('/');
    const response = await  GlobalService.ajax('post',uri,params.form)
    if(response.status == 200) {
      commit('SET_COMPANY_INSTRUCTION_ATTACHMENTS',response.data)
      return true;
    }
    return false;
  },

  async getVesselAttachments({commit},params) {
    commit('SET_COMPANY_INSTRUCTION_ATTACHMENTS',[])
    params._source='Company Instructions';
    const uri = [CI_VESSEL_INDEX,params.id,'attachment'].join('/');
    const response = await  GlobalService.ajax('get',uri,{});
    if(response.status == 200) {
      commit('SET_COMPANY_INSTRUCTION_ATTACHMENTS',response.data)
      return true;
    }
    return false;
  },

  async deleteVesselAttachment({commit},params) {
    commit('SET_COMPANY_INSTRUCTION_ATTACHMENTS',[])
    params._source='Company Instructions';
    const uri = [CI_VESSEL_INDEX,params.id,'attachment',params.attachment_id].join('/');
    const response = await  GlobalService.ajax('post',uri,{});
    if(response.status == 200) {
      commit('SET_COMPANY_INSTRUCTION_ATTACHMENTS',response.data)
      return true;
    }
    return false;
  },

  async getVesselComments({commit},params) {
    if(!!params.first_load){
      commit('SET_COMPANY_INSTRUCTION_COMMENTS',[])
      delete params.first_load
    }
    params._source='Company Instructions';
    const uri = [CI_COMMENT_INDEX].join('/');
    const queryString = UrlService.setQueryString(params);
    const response = await  GlobalService.ajax('get',uri+queryString,{});
    if(response.status == 200) {
      commit('APPEND_COMMENTS',response?.data?.data)
      return response;
    }
    return false;
  },

  async addComment({commit},params){
    const uri = [CI_COMMENT_INDEX].join('/');
    params._source='Company Instructions';
    const response = await  GlobalService.ajax('post',uri,params);
    if(response.status == 200) {
      commit('APPEND_COMMENTS',response.data)
      return true;
    }
    return false;
  },

  async getVesselResponse({commit},params) {
    const uri = [CI_VESSEL_INDEX , params.id ].join('/');
    params._source='Company Instructions';
    const response = await GlobalService.ajax('get', uri, params);
    if(response.status == 200) {
      return response.data
    }
  },

  async getCompanyInstructionAttachments({commit},params) {
    const uri = [CI_INDEX , params.id,'attachments' ].join('/');
    params._source='Company Instructions';
    const response = await GlobalService.ajax('get', uri, params);
    if(response.status == 200) {
      commit('SET_COMPANY_INSTRUCTION_FILES',response.data)
      return response.data
    }
  },

  async deleteCompanyInstructionAttachment({commit},params) {
    const uri = [CI_INDEX , params.referencable_id,'attachment',params.id].join('/');
    params._source='Company Instructions';
    const response = await GlobalService.ajax('post', uri);
    if(response.status == 200) {
      commit('SET_COMPANY_INSTRUCTION_FILES',response.data)
      return response.data
    }
  },

  async getCompanyInstruction({commit},params) {
    const uri = [CI_INDEX ,params.id].join('/');
    const response = await GlobalService.ajax('get', uri);
    if(response.status===200) {
      return response?.data ?? false;
    }
  }
}

const getters = {
  companyInstructions:(state)=>state.companyInstructions,
  instructionAttachments:(state)=>state.instructionAttachments,
  instructionComments:(state)=>state.instructionComments,
  instructionFiles: (state) => state.instructionFiles,
  CIactivetrees: (state) => state.activetrees

}

export default {state,mutations,actions,getters}
