'use strict'
export const DataService = {
  caseException: ['status', 'vmc_email'
    , 'email', 'fb_mail', 'route',
    'allowance', 'password', 'username',
    'confirmPassword', 'personal_email',
    'crewImage', 'vessel_image'],

  keyExceptions: ['attachments'],
  capitalizeProperties(value) {
    try {
      Object.keys(value).forEach(key => {
        if (this.keyExceptions.includes(key) || !value[key]) {
          return value
        } else if (value[key] && typeof value[key] === 'string') {
          if (!this.caseException.includes(key)) {
            value[key] = (value[key]) ? String(value[key]).toUpperCase() : ''
          }
        } else {
          value[key] = this.capitalizeProperties(value[key])
        }

        // if(value[key] && typeof value[key] === 'string') {
        //   if(!this.caseException.includes(key)) {
        //     value[key] = (value[key]) ? String(value[key]).toUpperCase() : '';
        //   }
        // } else if(this.keyExceptions.includes(key)) {
        //   return value;
        // } else if(!value[key]) {
        //   return value;
        // } else {
        //   value[key] = this.capitalizeProperties(value[key]);
        // }
      })
    } catch (e) {
      return value
    }
    return value
  },
  computeAge(dateBuild,eventDate = null) {
    const birthday = new Date(dateBuild).getTime()
    const currentDate = eventDate ? new Date(eventDate).getTime() :  new Date()
    return ((currentDate - birthday) / 3.15576e+10).toFixed(1)
  },
  paramParser(params,paramFields){
    let paramArray = {};
    Object.keys(params).forEach(key=>{
      if(Array.isArray(paramFields)){
        if(paramFields.includes(key)){
          Object.assign(paramArray,{[key]:params[key]});
        }
      }
      else{
        if( (params[key] || params[key] === 0) ) {
          Object.assign(paramArray,{[key]:params[key]});
        }
      }
    })
    return paramArray;
  }
}

export default {DataService}
