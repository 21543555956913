import axios from "axios";
import {
  ALL_USERS,
  CHANGE_PASSWORD,
  CREATE_USER,
  RESET_PASSWORD,
  UPDATE_USER,
  UPDATE_USER_ROLE_PERMISSIONS
} from "@/services/ConstantService";
import {GlobalService} from "@/services/GlobalService";
import {Form} from "form-backend-validation";

export const UserService = {
  async getUserList(params) {
    return await axios.get(ALL_USERS, {params}).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'GET USERS LIST SERVICE')
      return false
    })
  },

  async getUser(id) {
    return await axios.get(`${ALL_USERS}/${id}`).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'getUser SERVICE')
      return false
    })
  },

  async deleteUser(id) {
    return await axios.post(`${ALL_USERS}/${id}`, {_method: 'delete'}).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'deleteUser SERVICE')
      return false
    })
  },

  async createUser(user) {
    // Check @/components/settings/user/NewUser.vue for reference
    return await user.post(CREATE_USER)
  },

  async updateUser(user) {
    // Check @/components/settings/user/Profile.vue for reference
    return await user.patch(`${UPDATE_USER}/${user.id}`)
  },

  async updatePassword(params) {
    return await axios.post(CHANGE_PASSWORD,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'UPDATE PASSWORD SERVICE')
      return error
    })
  },

  async resetPassword(params) {
    return await axios.post(RESET_PASSWORD,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'UPDATE PASSWORD SERVICE')
      return error
    })
  },

  async updateRolePermissions(params) {
    let url = `${UPDATE_USER_ROLE_PERMISSIONS}/${params.userId}`
    return await axios.post(url,params).then(response => {
      return response.data ?? false
    }).catch(error => {
      GlobalService.handleError(error, 'UPDATE USER ROLE PERMISSION SERVICE')
      return error
    })
  },
}

export default { UserService }
