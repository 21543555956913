export default {
  methods: {
    getByKey(obj, str_key) {
      let tempObj = {};
      let tree = str_key.includes('.') ? str_key.split('.') : str_key;

      if (Array.isArray(tree)) {
        for (let i = 0; i <= tree.length; i++) {
          if (obj[tree[i]] && obj.hasOwnProperty(tree[i]) && typeof obj[tree[i]] === "object") {
            tempObj = obj[tree[i]];
            tree.splice(i, 1)
            tree = tree.join('.')
            return this.getByKey(tempObj, tree)
          }
        }
      } else {
        return obj[tree] ? obj[tree] : null;
      }
    },
    pscGet(str_key, default_value = null, force_if_default = false) {
      if (force_if_default && !!default_value) {
        return default_value;
      }

      if (typeof this.pscReport == 'undefined') {
        console.log("pscReport object is not defined");
        return default_value;
      }

      const value = this.getByKey(this.pscReport, str_key);
      return !!value ? value : default_value;
    },
    rsGet(str_key, default_value = null, force_if_default = false) {
      if (force_if_default && !!default_value) {
        return default_value;
      }

      if (typeof this.rsReport == 'undefined') {
        console.log("rsReport object is not defined");
        return default_value;
      }

      const value = this.getByKey(this.rsReport, str_key);
      return !!value ? value : default_value;
    },

    dataDiff(param1,param2){
      var count = 0;
      if(!param1 || !param2) return false;
      Object.keys(param1).forEach((key)=>{
        if(!param2.hasOwnProperty(key) || !param1.hasOwnProperty(key)) {
          return;
        }
        if(param1[key] != param2[key]) count++;
      })

       return !!count
    },

  }
}
