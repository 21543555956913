import {mapActions, mapGetters} from "vuex";
import store from "@/store";
import {
  IMPERSONATOR_LOGOUT,
  IMPERSONATOR_TOKEN_NAME,
  IMPERSONATOR_USER,
  IMPERSONATOR_USER_DETAILS,
  TOKEN_NAME,
  USER_DETAILS
} from "@/services/ConstantService";
import {AlertService} from "@/services/AlertService";
import {GlobalService} from "@/services/GlobalService";
import {AuthenticationService} from "@/services/AuthenticationService";


export const permissionMixin = {
  computed: {
    ...mapGetters(['user', 'userPermissions', 'usersByRole', 'usersByRoleLoaded'])
  },
  methods: {
    ...mapActions(['pullUserDetails', 'impersonateGetUsers']),
    isElite() {
      return (store.getters.user.roles_permissions.roles).includes('super-user');
    },
    isAllowed(permissionName) {
      // return this.userPermissions.includes(permissionName)
      if (store.getters.user.roles_permissions.roles) {
        if (Array.isArray(permissionName)) {
          return permissionName.some(permission => {
            return store.getters.user.roles_permissions.permissions.includes(permission)
          })
        }
        return store.getters.user.roles_permissions.permissions.includes(permissionName)
      }
    },
    isOwnVessel(reportType = null, vessel_id = null) {
      vessel_id = parseInt(vessel_id);
      if (Number.isInteger(vessel_id) && vessel_id > 0) {
        return store.getters.user.vessel_id == vessel_id;
      }

      if (!store.getters.user.vessel_id) {
        return true;
      }

      if (reportType === 'pscReport') {
        return store.getters.user.vessel_id === store.getters.pscReport.vessel_id
      } else {
        return store.getters.user.vessel_id === store.getters.accidentIncidentReport.vessel_id
      }

    },
    // || !store.getters.vessel.id
    // return (store.getters.user.vessel_id === store.getters.accidentIncidentReport.vessel_id) ||
    //   store.getters.user.vessel_id === store.getters.vessel.id

    requiredFields(accidentIncident) {
      return {
        'vessel_id': accidentIncident.vessel_id,
        'title': accidentIncident.title,
        'event_class_id': accidentIncident.event_class_id,
        'event_date': accidentIncident.event_date,
        'eventClass': accidentIncident.eventClass,

        'personInCharge': accidentIncident.personInCharge,
        'responsiblePerson': accidentIncident.personInCharge,
        'place': accidentIncident.place,
        'charterer': accidentIncident.charterer,

        'event_details': accidentIncident.event.event_details,
        'eventOne': accidentIncident.event.event_one_id,
        'eventTwo': accidentIncident.event.event_two_id,
        'eventThree': accidentIncident.event.event_three_id,

        'isDelaySched': accidentIncident.loss.is_delay_schedule,
        'delaySchedHours': accidentIncident.loss.delay_sched_hour,
        'delaySchedDay': accidentIncident.loss.delay_sched_day,
        'delaySchedMinute': accidentIncident.loss.delay_sched_minute,
        'delaySchedRemarks': accidentIncident.loss.delay_sched_remarks,

        'immediateCause': accidentIncident.cause.immediate_cause_act_id,
        'rootCause': accidentIncident.cause.root_cause_id,

        'instruction': accidentIncident.corrective.instruction,
        'instruction_date': accidentIncident.corrective.instruction_date,
        'action_taken': accidentIncident.corrective.action_taken,
        'action_taken_date': accidentIncident.corrective.action_taken_date,
        'reported_by': accidentIncident.corrective.reported_by,

        'details': accidentIncident.preventive.details,
      }
    },

    canReadyToClose(accidentIncident) {
      return this.checkObjects(this.requiredFields(accidentIncident));
    },

    checkObjects(object) {
      return Object.keys(object).every(item => {
        if (['delaySchedHours', 'delaySchedDay', 'delaySchedMinute', 'delaySchedRemarks'].includes(item)) {
          return true;
        }
        if (item === 'isDelaySched') {
          if (object[item]) {
            return ['delaySchedHours', 'delaySchedDay', 'delaySchedMinute', 'delaySchedRemarks'].every(item2 => {
              return !(object[item2] === null || object[item2] === undefined || object[item2] === '')
            })
          }
        }
        return !(object[item] === null || object[item] === undefined || object[item] === '')
      });
    },

    canClose(accidentIncident) {
      const READY_TO_CLOSE = 4
      return accidentIncident.report_status_id === READY_TO_CLOSE;
    },

    getUserRole(user, color = false) {
      const role_colors = {
        'super-user': '#f65252',
        'director': '#b599d0',
        'general-manager': '#F4B084',
        'manager': '#FFD966',
        'superintendent': '#B4C6E7',
        'instructor': '#C6E0B4',
        'vessel': '#b4b4b4',
        'staff': '#FFF',
      };

      if (color && (typeof user === 'string' || user instanceof String)) {
        user = user.replaceAll('_', '-');
        return Object.keys(role_colors).includes(user) ? role_colors[user] : 'none';
      }

      let role = user.roles_permissions.roles[0];
      role = role ? role : 'staff';

      const role_color = role_colors.hasOwnProperty(role) ? role_colors[role] : 'none';

      return color ? role_color : role.replace('-', ' ').toUpperCase();
    },

    async impersonateUser(user) {
      if (localStorage.getItem(IMPERSONATOR_TOKEN_NAME) || localStorage.getItem(IMPERSONATOR_USER_DETAILS)) {
        return AlertService.errorAlert('You are currently impersonating a user', 'Impersonate User');
      } else if (!this.isElite()) {
        return AlertService.errorAlert('You are not authorize to use this feature', 'Impersonate User');
      }

      swal.fire({
        'title': "Impersonating '" + (user.name).toString() + "'...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      swal.showLoading();

      let response = await GlobalService.ajax('post', IMPERSONATOR_USER, {id: user.id});

      if (response.status === 200) {
        let data = response.data;
        const impersonator_details = localStorage.getItem(USER_DETAILS);
        const impersonator_token = localStorage.getItem(TOKEN_NAME);

        localStorage.setItem(IMPERSONATOR_USER_DETAILS, impersonator_details);
        localStorage.setItem(IMPERSONATOR_TOKEN_NAME, impersonator_token);

        localStorage.removeItem(USER_DETAILS);
        localStorage.removeItem(TOKEN_NAME);

        localStorage.setItem(USER_DETAILS, JSON.stringify(data.user));
        localStorage.setItem(TOKEN_NAME, data.token);

        AuthenticationService.setHeader(data.token);

        await this.$router.push({name: 'Base'});
        await location.reload();
      }
    },

    impersonating() {
      return localStorage.getItem(IMPERSONATOR_USER_DETAILS);
    },

    impersonateGetUserBgColor() {
      return this.impersonating() ? 'background-color: ' + this.getUserRole(this.user, true) : null;
    },

    async logout() {
      const authLogout = await AuthenticationService.logout();

      if (localStorage.getItem(IMPERSONATOR_LOGOUT)) {
        localStorage.removeItem(IMPERSONATOR_LOGOUT);

        this.pullUserDetails();

        if (this.isElite()) {
          await this.impersonateGetUsers();
        }

        await this.$router.push({name: 'Dashboard', query: {_ilt: (Math.round(+new Date() / 1000)).toString()}});
      } else {
        await this.$router.push({name: 'Login'});
      }
    },

    isCiOwnInstruction(instruction){
      return instruction.vessel_id ===  store.getters.user.vessel_id
    }
  }
}
