<template>
  <div class="text-center" style="background: white">
    <div class="spinner-border text-primary my-3" style="width: 3rem; height: 3rem;" role="status">
      <span class="sr-only">Loading...</span>
    </div>

    <h5 class="font-weight-bolder">Loading Please Wait . . . . .</h5>
  </div>
</template>

<script>
export default {
  name: "AppLoading"
}
</script>

<style scoped>

</style>
